<template>
  <div class="main">
    <div class="container">
      <van-nav-bar title="搜索" left-arrow @click-left="onClickLeft"/>
      <div class="login">
        <van-image :src="require('@/assets/logo.png')" />
      </div>
      <van-search v-model="value" placeholder="请输入搜索关键词" @cancel="onCancel" shape="round" background="#ededed"/>
      <div style="margin: 20px 16px;">
        <van-button round block type="info" native-type="submit" @click="goDetail">提交</van-button>
      </div>
      <div class="newslist">
        <van-list v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  >
          <van-cell v-for="(item,index) in list"
                    :key="index"
                    is-link>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title >
              <span class="custom-title">{{item.post_title}}</span>
              <span class="custom-danger" @click="onCopy(item.random_id)">{{item.random_id}}</span>
            </template>
            <template #right-icon>
              <van-icon name="chat-o"
                        class="icon"
                        @click="onCopy(item.random_id)" />
            </template>
          </van-cell>

        </van-list>
      </div>

    </div>
    <DjhrqFooter />
  </div>

</template>

<script>
import DjhrqFooter from "@/components/footer"
export default {
  name: 'DjhrqIndex',
  components: {
    DjhrqFooter
  },
  data () {
    return {
      value: '',
      keyword: '',
      page: 1,
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    onClickLeft () { 
      this.$router.back()
    },
    onLoad () {
      let that = this
      that.$axios.get('/api/portal/search', { params: { page: that.page,keyword: that.value } }).then(res => {
        if (that.page == 1) {
          console.log(res.data);
          that.list = res.data.data
        } else {
          that.list = that.list.concat(res.data.data)
        }
        // 加载状态结束
        that.loading = false;
        that.page++
        if (res.data.data.length == 0 || res.data.data == null || res.data.data.length < 10) {
          that.finished = true
          return
        }
        // 数据全部加载完成
        // if (this.list.length >= 10) {
        //   this.finished = true;
        // }
      }).catch((error) => {
        console.log(error);
      });
    },
    onCopy(code){
      this.scrollToTop()
      this.$copyText(code.toString()).then(() => {
        this.keyword = code.toString()
        this.$toast.success('已成功复制到剪切板')
         this.$router.push({
            name: "index",
            params: {
                keyword: this.keyword,
          },
        });   
      }).catch(() => {
        this.$toast.fail('复制失败');
      })
    },
    goDetail() {
      if(this.value == null  || this.value  == '' ){
       return this.$toast.fail('搜索关键词不能为空！');  
      }
      this.$axios.get('/api/portal/search', { params: { keyword: this.value } }).then(res => {
        if(res.data.data == ""){
          this.$toast('没有在找到这个编号');      
          this.value = ''
          return     
        }else{
          this.onLoad()
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    /**
     * 滚动到顶部
     */
    scrollToTop() {
      let $this = this;
      // 返回顶部动画特效
      setTimeout(function animation() {
        if ($this.scrollTop > 0) {
          setTimeout(() => {
            // 步进速度
            $this.scrollTop = $this.scrollTop - 30;
            // 返回顶部
            if(document.documentElement.scrollTop > 0) {
              document.documentElement.scrollTop = $this.scrollTop - 30;
            } else if (window.pageYOffset > 0) {
              window.pageYOffset = $this.scrollTop - 30;
            } else if (document.body.scrollTop > 0) {
              document.body.scrollTop = $this.scrollTop - 30;
            }
            animation();
          }, 1);
        }
      }, 1);
    },
    onCancel() {
      this.Toast('取消');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  ::v-deep .van-search .van-cell{
    padding: 10px 5px 10px 0;
  }
  ::v-deep .van-search__content{
    padding-right: 10px;
  }
  .login {
    margin-top: 20px;
    text-align: center;
    ::v-deep .van-image img {
      height: 105px;
      width: 280px;
    }
  }
  .newslist {
    margin-top: 30px;
    ::v-deep .van-list {
      padding: 0 18px;
      overflow: hidden;
    }
    .custom-title {
      margin-right: 4px;
    }
    .custom-danger {
      color: #969799;
    }
    .icon {
      font-size: 20px;
      line-height: inherit;
    }
  }
}
</style>