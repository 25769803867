<template>
  <div class="footer">
    <p>
      @百川人力 | <a href="https://beian.miit.gov.cn" target="_blank" style="color: #323233;">鲁ICP备2024065844号</a>
    </p>
    <p>
      本服务由东京生活圈提供
    </p>
  </div>
</template>

<script>
export default {
  name: 'DjhrqFooter',
  props: {
    msg: String
  }
}
</script>
<style lang="scss">
  .footer {
    width: 100%;
    & p {
      font-size: 14px;
      line-height: 12px;
      text-align: center
    }
  }
</style>


